<template>
  <div ref="contentContainer">
    <div class="d-flex justify-around">
      <div class="mr-5">
        <div class="d-flex justify-between">
          <TextAtom
            class="title-sections__location"
            :value="$t('dashboard.digital_profile.programs.title')"
            tag="label"
            font="poppins"
            weight="600"
            size="big"
          />
          <SVGIcon
            class="clickable"
            icon="more.svg"
            size="30"
            @click="programsFlowModal = true"
          />
        </div>
        <TextAtom
          class="des-sections__location mb-5 d-flex left-text programs-add--subtitle"
          :value="$t('dashboard.digital_profile.programs.title_field')"
          tag="p"
          font="inter"
          weight="400"
          size="normal"
          color="neutral-800"
        />
      </div>
    </div>
    <div v-if="!hasPrograms" class="programs-card">
      <Tabs
        v-model="tab"
        :current-year="currentYear"
        :second-year="secondYear"
        :third-year="thirdYear"
      />
      <div class="programs-add">
        <TextAtom
          :value="$t('dashboard.digital_profile.programs.add_program')"
          tag="label"
          font="poppins"
          color="primary-dark"
          weight="500"
          size="big"
        />
        <TextAtom
          class="mt-3"
          :value="$t('dashboard.digital_profile.programs.add_program_field')"
          tag="label"
          font="inter"
          color="primary-dark"
          weight="400"
          size="subtitle"
        />
        <SVGIcon
          class="clickable mt-7"
          icon="more.svg"
          size="30"
          @click="programsFlowModal = true"
        />
      </div>
    </div>

    <div v-else>
      <v-card
        class="programs-card"
        color="#FFFFFF00"
      >
        <Tabs
          v-model="tab"
          :current-year="currentYear"
          :second-year="secondYear"
          :third-year="thirdYear"
        />
        <div v-for="(grade, i) in gradesArray" :key="i">
          <div v-for="(program, index) in grade" :key="index">
            <Grade
              v-if="hasProgramsOfTheYear(program)"
              class="mb-5"
              :grades="program"
              :index-grade="index"
              :year="getYear()"
              @deleteGrade="deleteGrade(i, index)"
              @setCampusGrades="setCampusGrades(i, index, ...arguments)"
            />
          </div>
        </div>
      </v-card>
    </div>
    <div
      class="d-flex left-text clickable mt-5"
      @click="programsFlowModal = true"
      @keydown="programsFlowModal = true"
    >
      <SVGIcon
        icon="more-border-gray.svg"
        size="17"
      />
      <TextAtom
        class="ml-2 clickable"
        :value="$t('dashboard.digital_profile.programs.add-grade')"
        tag="label"
        font="inter"
        color="neutral-800"
        weight="400"
        size="normal"
      />
    </div>
    <v-dialog
      v-if="programsFlowModal"
      v-model="programsFlowModal"
      :max-width="'480'"
      transition="slide-x-transition"
      :content-class="mobile
        ? 'system-dialogs-mobile system-dialogs-mobile--white' : 'system-dialogs system-dialogs--wide'"
    >
      <ProgramsSelectionFlow
        @close="programsFlowModal = false"
        @setNewGrade="setNewGrade"
      />
    </v-dialog>
    <SaveButton :loading="loading" :is-disabled="isDisabled" @save="submitPrograms" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CONFIG from '@/config';
import Tabs from '@/components/molecules/tabs/Tabs.vue';
import { LABELS } from '@/constants/explorer/labels';
import SaveButton from '../../utils/SaveButton.vue';
import Grade from './Grade.vue';
import ProgramsSelectionFlow from './ProgramsSelectionFlow.vue';

export default {
  name: 'ProgramsSection',
  components: {
    TextAtom,
    SaveButton,
    ProgramsSelectionFlow,
    SVGIcon,
    Grade,
    Tabs,
  },
  data() {
    return {
      loading: false,
      programsFlowModal: false,
      gradesArray: [],
      programsArray: [],
      tab: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      secondYear: new Date().getFullYear() + 1,
      thirdYear: new Date().getFullYear() + 2,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    isDisabled() {
      return this.gradesArray.length === 0;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasPrograms() {
      return this.gradesArray.length > 0;
    },
  },
  watch: {
    campusDetail: {
      handler() {
        const programCampus = this.campusDetail?.program_campus;
        if (programCampus) {
          this.setArray([...programCampus]);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.checkLabelInmediatly({ keyLabel: LABELS.PROGRAM_GENDERS });
    await this.checkLabelInmediatly({ keyLabel: LABELS.SHIFTS });
    this.setArray(this.campusDetail?.program_campus);
  },
  methods: {
    ...mapActions({
      campusProgramsPatch: 'institutions/campusProgramsPatch',
      gradeTracksByCombination: 'institutions/gradeTracksByCombination',
      checkLabelInmediatly: 'explorer/checkLabelInmediatly',
    }),
    getYear() {
      return new Date().getFullYear() + this.tab;
    },
    setNewGrade(grades, shifts, gender) {
      console.log('grades', grades);
      console.log('shifts', shifts);
      console.log('gender', gender);
      const matrix = [...this.gradesArray];
      if (!Array.isArray(grades)) {
        return;
      }
      grades.forEach((grade) => {
        shifts.forEach((sh) => {
          const program = {
            id: undefined,
            gender_label_id: gender,
            shift_label_id: sh.id,
            personalized_name: null,
            gradetrack: {
              id: null,
              stage_label_id: grade.stage_label_id,
              modality_label_id: null,
              specialty_label_id: null,
              grade_label_id: grade.grade_label_id,
              order: grade.order,
            },
            year: this.getYear(),
          };
          const stageId = grade.stage_label_id;
          const { order } = grade;

          if (!matrix[stageId - 1]) {
            matrix[stageId - 1] = [];
          }

          if (!matrix[stageId - 1][order]) {
            matrix[stageId - 1][order] = [];
          }

          matrix[stageId - 1][order].push(program);
        });
      });
      this.gradesArray = matrix;
      this.programsArray = this.gradesArray.flat(2);
    },
    setArray(programs) {
      // Check if programs is an array before sorting
      if (Array.isArray(programs)) {
        const newPrograms = programs
          .map((prog) => ({
            id: prog.id ?? undefined,
            gender_label_id: prog.gender_label.id,
            shift_label_id: prog.shift_label.id,
            personalized_name: prog.personalized_name,
            gradetrack: {
              id: prog.gradetrack.id,
              stage_label_id: prog.gradetrack.stage_label.id,
              modality_label_id: prog.gradetrack.modality_label.id,
              specialty_label_id: prog.gradetrack.specialty_label.id,
              grade_label_id: prog.gradetrack.grade_label.id,
              order: prog.gradetrack.grade_label.order,
            },
            year: prog.year,
          }));
        const result = newPrograms.reduce((acc, obj) => {
          const stageId = obj.gradetrack.stage_label_id;
          const { order } = obj.gradetrack;

          if (!acc[stageId - 1]) {
            acc[stageId - 1] = [];
          }

          if (!acc[stageId - 1][order]) {
            acc[stageId - 1][order] = [];
          }

          acc[stageId - 1][order].push(obj);
          return acc;
        }, {});
        const resultCopy = { ...result };
        this.gradesArray = Object.values(resultCopy);
      }
      this.programsArray = this.gradesArray.flat(2);
      return this.gradesArray;
    },
    deleteGrade(i, index) {
      if (this.gradesArray[i].length === 1) {
        this.gradesArray.splice(i, 1);
      } else {
        this.gradesArray[i].splice(index, 1);
      }
      this.programsArray = this.gradesArray.flat(2);
    },
    setCampusGrades(i, index, grades) {
      this.gradesArray[i][index] = grades;
      this.programsArray = this.gradesArray.flat(2);
    },
    async submitPrograms() {
      this.loading = true;

      // Replace null values with defaults
      const promises = this.programsArray.map(async (program) => {
        const programUpdated = program;
        if (program.gradetrack.modality_label_id === null) {
          programUpdated.gradetrack.modality_label_id = this.getDefaultModalityId();
        }
        if (program.gradetrack.specialty_label_id === null) {
          programUpdated.gradetrack.specialty_label_id = this.getDefaultSpecialtyId();
        }
        if (program.gender_label_id === null) {
          programUpdated.gender_label_id = this.getDefaultGenderId();
        }
        if (program.gradetrack.id === null) {
          programUpdated.gradetrack.id = await this.getGradeTrackId(
            programUpdated.gradetrack.grade_label_id,
            programUpdated.gradetrack.stage_label_id,
            programUpdated.gradetrack.modality_label_id,
            programUpdated.gradetrack.specialty_label_id,
          );
        }
      });

      await Promise.all(promises);

      // Patch the data
      this.campusProgramsPatch({ campusUuid: this.campusDetail.uuid, campusData: this.programsArray })
        .then(() => {
          this.loading = false;
        });
    },
    getDefaultGenderId() {
      const { tenant } = CONFIG;
      if (tenant === 'chile' || tenant === 'colombia') {
        return 3;
      } if (tenant === 'newhaven') {
        return 1;
      }
      return 1;
    },
    getDefaultModalityId() {
      return 1;
    },
    getDefaultSpecialtyId() {
      return 1;
    },
    async getGradeTrackId(gradeId, stageId, modalityId, specialtyId) {
      const gradetrack = await this.gradeTracksByCombination({
        gradeId,
        stageId,
        modalityId,
        specialtyId,
      });
      return gradetrack[0].id;
    },
    hasProgramsOfTheYear(programs) {
      if (!programs) return false;
      // see if any program of the array has year === getYear()
      return programs.some((program) => program.year === this.getYear());
    },
  },
};
</script>
