<template>
  <div
    :class="[
      mobile ? 'dashboard-section-mobile' : 'dashboard-section',
      paddingSection ? 'padding-section-dashboard' : '',
    ]"
    :style="{
      '--row': row,
      '--col': col,
      '--background-color': background,
      '--mobile-height': mobileHeight,
      '--padding': paddingSection,
      '--grid-area': gridArea,
      ...styles,
    }"
    @click="onClick()"
    @keydown="onClick()"
  >
    <div
      v-if="showTitle"
      class="dashboard-text"
      :class="
        fullSpace
          ? 'dashboard-section-title-fullspace dashboard-section-title'
          : 'dashboard-section-title'
      "
    >
      {{ title }}
    </div>
    <div
      class="dashboard-scrollbar"
      :class="getBodyClass"
      :style="{
        '--mobile-height': mobileHeight,
        '--radius': showTitle ? '0px 0px 5px 5px' : '5px 5px 5px 5px',
        '--body-height': bodyHeight,
        '--paddingMobile': paddingMobile,
      }"
    >
      <div
        :is="name"
        v-bind="{ ...sectionProps, ...$props }"
        v-on="$listeners"
        @show-explorer-meeting-modal="$emit('show-explorer-meeting-modal')"
      />
    </div>
  </div>
</template>
<script>
import ComingSoon from '@/components/organisms/dashboard/ComingSoon.vue';
import WidgetFeedback from '@/components/organisms/widget/WidgetFeedback.vue';
import Admissions from './sections/Admissions.vue';
import Analytics from './sections/Analytics.vue';
import DFM from './sections/DFM.vue';
import DashboardMap from './sections/DashboardMap.vue';
import DgeApplicationsPanel from './sections/DgeApplicationsPanel.vue';
import DgeHeadmasterInfo from './sections/DgeHeadmasterInfo.vue';
import DgeLgApplications from './sections/DgeLgApplications.vue';
import DigitalProfile from './sections/DigitalProfile.vue';
import DigitalProfileEdit from './sections/DigitalProfileEdit.vue';
// import EngagementWidget from './sections/EngagementWidget.vue';
import ImageGallery from './sections/ImageGallery.vue';
import Location from './sections/Location.vue';
import Messages from './sections/Messages.vue';
import MessagesHeadmaster from './sections/MessagesHeadmaster.vue';
import PartnershipContainer from './sections/PartnershipContainer.vue';
import Profile from './sections/Profile.vue';
import ProfileEdit from './sections/ProfileEdit.vue';
import SchoolList from './sections/SchoolList.vue';
import Simulation from './sections/Simulation.vue';
import Skeleton from './sections/Skeleton.vue';
import Teaching from './sections/Teaching.vue';
import Tools from './sections/Tools.vue';
import ToolsHeadmaster from './sections/ToolsHeadmaster.vue';
import ToolsStats from './sections/ToolsStats.vue';
import VacanciesWidget from './sections/VacanciesWidget.vue';
import WhiteLabel from './sections/WhiteLabel.vue';
import AdmissionCategorySelector from './sections/admissions/AdmissionCategorySelector.vue';
import AdmissionFrequentlyAskedQuestions from './sections/admissions/AdmissionFrequentlyAskedQuestions.vue';
import AdmissionProcess from './sections/admissions/AdmissionProcess.vue';
import AdmissionVacancies from './sections/admissions/AdmissionVacancies.vue';
import AnalyticsSelectorInstagram from './sections/analytics/AnalyticsSelectorInstagram.vue';
import AnalyticsSelectorVacancies from './sections/analytics/AnalyticsSelectorVacancies.vue';
import AnalyticsSelectorPaes from './sections/analytics/AnalyticsSelectorPaes.vue';
import AnalyticsGraph from './sections/analytics/Graph.vue';
import AnalyticsSectionChoice from './sections/analytics/SectionChoice.vue';
import AnalyticsSectionTile from './sections/analytics/subsections/SectionTile.vue';
import Contacts from './sections/digital-profile/Contacts.vue';
import PanelEditor from './sections/digital-profile/PanelEditor.vue';
import PanelSelector from './sections/digital-profile/PanelSelector.vue';
import TetherPrime from './sections/digital-profile/TetherPrime.vue';
import ImageTagger from './sections/gallery/ImageTagger.vue';
import ImageUploader from './sections/gallery/ImageUploader.vue';
import ListOfMessages from './sections/notifications/ListOfMessages.vue';
import MessagesCategories from './sections/notifications/MessagesCategories.vue';
import SearchingTeachers from './sections/teaching/SearchingTeachers.vue';
import UpcomingEvents from './sections/UpcomingEvents.vue';
import SaeResultsReport22 from './sections/analytics/SaeResultsReport22.vue';
import SaeResultsReport21 from './sections/analytics/SaeResultsReport21.vue';
import SaeCompetitorsReport22 from './sections/analytics/SaeCompetitorsReport22.vue';
import SaeCompetitorsReport21 from './sections/analytics/SaeCompetitorsReport21.vue';
import PublicRegister from './sections/PublicRegister.vue';
import PublicRegisterAdmin from './sections/PublicRegisterAdmin.vue';

export default {
  name: 'MainSection',
  components: {
    DigitalProfileEdit,
    ImageGallery,
    Admissions,
    DashboardMap,
    Tools,
    Profile,
    DigitalProfile,
    DFM,
    MessagesHeadmaster,
    Messages,
    SchoolList,
    Skeleton,
    SearchingTeachers,
    Teaching,
    AdmissionProcess,
    AdmissionCategorySelector,
    AdmissionVacancies,
    AdmissionFrequentlyAskedQuestions,
    ImageUploader,
    ImageTagger,
    ToolsHeadmaster,
    PanelEditor,
    PanelSelector,
    Analytics,
    AnalyticsSectionTile,
    AnalyticsSectionChoice,
    AnalyticsGraph,
    ListOfMessages,
    MessagesCategories,
    ToolsStats,
    ProfileEdit,
    DgeHeadmasterInfo,
    PartnershipContainer,
    DgeLgApplications,
    DgeApplicationsPanel,
    VacanciesWidget,
    Simulation,
    WhiteLabel,
    WidgetFeedback,
    Location,
    ComingSoon,
    TetherPrime,
    Contacts,
    AnalyticsSelectorInstagram,
    AnalyticsSelectorVacancies,
    AnalyticsSelectorPaes,
    UpcomingEvents,
    SaeResultsReport22,
    SaeResultsReport21,
    SaeCompetitorsReport22,
    SaeCompetitorsReport21,
    PublicRegister,
    PublicRegisterAdmin,
  },
  props: {
    mobileHeight: {
      /* if given, change mobile height of section */
      required: false,
      default: null,
      type: String,
    },
    fullSpace: {
      /* if true, section content uses the whole space */
      required: false,
      default: false,
      type: Boolean,
    },
    showTitle: {
      /* Wether to display title or not. true by default */
      required: false,
      default: true,
      type: Boolean,
    },
    title: {
      /* Section title */
      required: false,
      default: '',
      type: String,
    },
    name: {
      /* name of section. */
      required: true,
      type: String,
    },
    row: {
      /* specifies the rows taken by the section. if unspecified, it uses 1x1 */
      required: false,
      type: String,
      default: '',
    },
    col: {
      /* specifies the cols taken by the section. if unspecified, it uses 1x1 */
      required: false,
      type: String,
      default: '',
    },
    sectionProps: {
      /* specifies all other possible props used by the section. */
      required: false,
      type: Object,
      default: () => {},
    },
    background: {
      required: false,
      type: String,
      default: '#F5F5F5',
    },
    styles: {
      required: false,
      default: () => {},
      type: Object,
    },
    paddingSection: {
      required: false,
      type: String,
      default: '10px',
    },
    paddingMobile: {
      required: false,
      type: String,
      default: '10px',
    },
    // We can disable this rule here because while it is true that we are not using `loaded` in this component,
    // it is being propagated to the child component (`v-bind="{...$props}"`), which is using it.
    // eslint-disable-next-line vue/no-unused-properties
    loaded: {
      required: false,
      type: Boolean,
      default: true,
    },
    bodyHeight: {
      required: false,
      type: String,
      default: '100%',
    },
    gridArea: {
      required: true,
      type: String,
      default: '',
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getBodyClass() {
      const classes = [];
      if (this.fullSpace) {
        classes.push('dashboard-section-body-fullspace');
      } else if (this.mobile) {
        classes.push('dashboard-section-body-mobile');
      } else {
        classes.push('dashboard-section-body');
      }
      return classes;
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>
