<template>
  <div>
    <div>
      <div v-if="mobile">
        <v-select
          v-model="currentPanelSelect"
          :items="panels"
          item-text="title"
          return-object
          solo
          color="black"
          dense
          hide-details
          class="custom-select text--center"
          @input="setPanelSelect"
        >
          <template slot="selection" slot-scope="data">
            <div class="d-flex flex-row">
              <picture class="panel-icon">
                <!-- icon -->
                <img :src="data.item.icon" alt="icon" />
                <!-- header -->
              </picture>
              <span class="mt-3"> {{ data.item.title }} </span>
            </div>
          </template>
          <template slot="item" slot-scope="data">
            <div class="d-flex flex-row">
              <picture class="panel-icon">
                <!-- icon -->
                <img :src="data.item.icon" alt="icon" />
                <!-- header -->
              </picture>
              <span class="mt-4"> {{ data.item.title }} </span>
            </div>
          </template>
        </v-select>
      </div>
      <div v-else>
        <div v-for="(panel, index) in panels" :key="index">
          <v-expansion-panel-header
            class="pl-2 pb-1 mt-2 px-0 pt-0 panel-selected rounded w-full"
            :class="currentPanel == panel.name ? 'panel-selected' : 'panel-unselected'"
            :style="panel.disabled ? 'background-color: #f2f2f2' : ''"
            :disabled="panel.disabled"
            expand-icon=""
            @click="panel.disabled ? () => {} : setPanel(panel)"
          >
            <picture class="panel-icon">
              <!-- icon -->
              <img :src="panel.icon" alt="icon" />
              <!-- header -->
            </picture>
            <h3
              class="campus-detail__panels__title panel-title ml-6"
              :class="currentPanel == panel.name ? 'panel-selected-text' : 'panel-unselected-text'"
            >
              {{ panel.title }}
            </h3>
          </v-expansion-panel-header>
          <v-divider />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdmissionsIcon from '@/assets/digitalProfile/admission.svg';
import AudiovisualIcon from '@/assets/digitalProfile/audiovisual_material.svg';
import ContactsIcon from '@/assets/digitalProfile/contact.svg';
import DescriptionIcon from '@/assets/digitalProfile/description.svg';
import EducationalProjectIcon from '@/assets/digitalProfile/educational_project.svg';
import ExtracurricularsIcon from '@/assets/digitalProfile/extracurriculars.svg';
import GeneralIcon from '@/assets/digitalProfile/info.svg';
import InfrastructureIcon from '@/assets/digitalProfile/infraestructure.svg';
import LanguagesIcon from '@/assets/digitalProfile/languages.svg';
import LocationIcon from '@/assets/digitalProfile/location.svg';
import PriceIcon from '@/assets/digitalProfile/payment.svg';
// import PerformanceIcon from '@/assets/digitalProfile/performance.svg';
import PridePointsIcon from '@/assets/digitalProfile/pride_points.svg';
import ProgramsIcon from '@/assets/digitalProfile/programs.svg';
import SocialsIcon from '@/assets/digitalProfile/social_media.svg';
import SportsIcon from '@/assets/digitalProfile/sports.svg';
import StudentsIcon from '@/assets/digitalProfile/students.svg';
import TeamIcon from '@/assets/digitalProfile/teachers.svg';
// import VacanciesIcon from '@/assets/digitalProfile/vacancies.svg';
import PrimeIcon from '@/assets/logos/tether.svg';
import trackMixPanel from '@/utils/mixpanel';
import { mapGetters } from 'vuex';
import TestimonialsIcon from '@/assets/digitalProfile/testimonials.svg';

export default {
  name: 'PanelSelector',
  data() {
    return {
      currentPanelSelect: null,
      currentPanel: 'general-info',
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      currentRole: 'roles/currentRole',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isAmbassador() {
      return this.currentRole.roleInfo.id === 4;
    },
    panels() {
      const panels = [
        {
          name: 'general-info',
          title: this.$t('dashboard.digital_profile.general_info.title'),
          icon: GeneralIcon,
          iconSize: '35px',
          show: true,
          padding: '0px',
        },
        {
          name: 'tether-prime',
          title: this.$t('dashboard.digital_profile.prime.title'),
          icon: PrimeIcon,
          iconSize: '35px',
          show: this.isAmbassador,
          padding: '0px',
        },
        {
          name: 'about',
          title: this.$t('dashboard.digital_profile.about.title'),
          icon: DescriptionIcon,
          iconSize: '35px',
          show: true,
        },
        {
          name: 'location',
          title: this.$t('dashboard.digital_profile.location.title'),
          icon: LocationIcon,
          iconSize: '25px',
          show: true,
        },
        {
          name: 'contacts',
          title: this.$t('dashboard.digital_profile.contacts.title'),
          icon: ContactsIcon,
          iconSize: '30px',
          show: true,
        },
        {
          name: 'pride-points',
          title: this.$t('dashboard.digital_profile.pride_points.title'),
          icon: PridePointsIcon,
          iconSize: '35px',
          show: true,
        },
        {
          name: 'educational-project',
          title: this.$t('dashboard.digital_profile.educational_proyect.title'),
          icon: EducationalProjectIcon,
          iconSize: '35px',
          show: true,
        },
        {
          name: 'testimonials',
          title: this.$t('dashboard.digital_profile.testimonials.title'),
          icon: TestimonialsIcon,
          iconSize: '35px',
          show: true,
        },
        {
          name: 'audiovisual-material',
          title: this.$t('dashboard.digital_profile.audiovisual_material.title'),
          icon: AudiovisualIcon,
          iconSize: '35px',
          show: true,
        },
        {
          name: 'admissions',
          title: this.$t('dashboard.digital_profile.admissions.title'),
          icon: AdmissionsIcon,
          iconSize: '30px',
          show: true,
        },
        {
          name: 'price',
          title: this.$t('dashboard.digital_profile.price.title'),
          icon: PriceIcon,
          iconSize: '30px',
          show: false,
        },
        {
          name: 'programs',
          title: this.$t('dashboard.digital_profile.programs.title'),
          icon: ProgramsIcon,
          iconSize: '30px',
          show: true,
        },
        // {
        //   name: 'vacancies',
        //   title: this.$t('dashboard.digital_profile.vacancies.title'),
        //   icon: VacanciesIcon,
        //   iconSize: '30px',
        //   show: true,
        // },
        // {
        //   name: 'performance',
        //   title: this.$t('dashboard.digital_profile.performance.title'),
        //   icon: PerformanceIcon,
        //   iconSize: '30px',
        //   show: true,
        //   disabled: false,
        // },
        {
          name: 'teachers',
          title: this.$t('dashboard.digital_profile.teachers.title'),
          icon: TeamIcon,
          iconSize: '25px',
          show: true,
        },
        {
          name: 'students',
          title: this.$t('campus-details.students'),
          icon: StudentsIcon,
          iconSize: '30px',
          show: true,
        },
        {
          name: 'infrastructure',
          title: this.$t('campus-details.infrastructure'),
          icon: InfrastructureIcon,
          iconSize: '35px',
          show: true,
        },
        {
          name: 'sports',
          title: this.$t('campus-details.activities.sports'),
          icon: SportsIcon,
          iconSize: '30px',
          show: true,
        },
        {
          name: 'extracurriculars',
          title: this.$t('campus-details.activities.extra_programmatic'),
          icon: ExtracurricularsIcon,
          iconSize: '30px',
          show: true,
        },
        {
          name: 'languages',
          title: this.$t('dashboard.digital_profile.languages.title'),
          icon: LanguagesIcon,
          iconSize: '30px',
          show: true,
        },

        {
          name: 'socials',
          title: this.$t('dashboard.digital_profile.socials.title'),
          icon: SocialsIcon,
          iconSize: '30px',
          show: true,
        },
      ];
      const newPanels = panels.filter((panel) => panel.show === true);
      const [firstPanel] = newPanels;
      this.setCurrentPanel(firstPanel);
      return newPanels;
    },
  },
  mounted() {
    this.$emit('setPanel', 'general-info', this.$t('dashboard.digital_profile.about.title'));
  },
  methods: {
    setCurrentPanel(firstPanel) {
      this.currentPanelSelect = firstPanel;
    },
    setPanelSelect() {
      this.currentPanel = this.currentPanelSelect.name;
      this.$emit('setPanel', this.currentPanelSelect.name, this.currentPanelSelect.title);
    },
    setPanel(panel) {
      if (this.currentPanel === panel.name) {
        this.currentPanel = null;
        this.$emit('setPanel', null, '');
      } else {
        this.currentPanel = panel.name;
        this.$emit('setPanel', panel.name, panel.title);
      }
      const clickText = 'click_dashboard_digital_profile_';
      trackMixPanel(clickText.concat(panel.name));
    },
  },
};
</script>
