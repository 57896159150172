<template>
  <div class="dashboard-section-content">
    <DigitalProfileModal :current-modal="currentModal" @setModal="setModal" />
    <div
      v-for="(panel, index) in panels"
      :key="index"
      :style="{ height: panel.name == currentPanel ? '100%' : 0, background: panel.background }"
    >
      <div
        :is="panel.name"
        v-if="panel.name == currentPanel"
        :mobile="mobile"
      />
    </div>
  </div>
</template>
<script>
import DigitalProfileModal from '@/components/dashboard/modals/DigitalProfileModal.vue';
import EditableGroup from '../../utils/EditableGroup.vue';
import EditableGroupList from '../../utils/EditableGroupList.vue';
import Subcontent from '../../utils/Subcontent.vue';
// Digital Profile
import About from './About.vue';
import Admissions from './Admissions.vue';
import AudiovisualMaterial from './AudiovisualMaterial.vue';
import Contacts from './Contacts.vue';
import EducationalProject from './EducationalProject.vue';
import Extracurriculars from './Extracurriculars.vue';
import GeneralInfo from './GeneralInfo.vue';
import Infrastructure from './Infrastructure.vue';
import Languages from './Languages.vue';
import Location from './Location.vue';
import Performance from './Performance.vue';
import Price from './Price.vue';
import PridePoints from './PridePoints.vue';
import Programs from './Programs.vue';
import Socials from './Socials.vue';
import Sports from './Sports.vue';
import Students from './Students.vue';
import Teachers from './Teachers.vue';
import Testimonials from './Testimonials.vue';
import TetherPrime from './TetherPrime.vue';
import Vacancies from './Vacancies.vue';

export default {
  name: 'PanelEditor',
  components: {
    EditableGroupList,
    EditableGroup,
    Subcontent,
    DigitalProfileModal,
    About,
    Admissions,
    EducationalProject,
    Infrastructure,
    Languages,
    Location,
    Performance,
    Price,
    PridePoints,
    Socials,
    Sports,
    Students,
    Extracurriculars,
    Teachers,
    AudiovisualMaterial,
    GeneralInfo,
    Vacancies,
    TetherPrime,
    Contacts,
    Programs,
    Testimonials,
  },
  props: {
    currentPanel: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      currentModal: null,
      panels: [
        {
          name: 'general-info',
        },
        {
          name: 'tether-prime',
        },
        {
          name: 'about',
        },
        {
          name: 'location',
          background: 'white',
        },
        {
          name: 'contacts',
        },
        {
          name: 'pride-points',
        },
        {
          name: 'educational-project',
        },
        {
          name: 'admissions',
        },
        {
          name: 'price',
          background: 'transparent',
        },
        // {
        //   name: 'performance',
        // },
        {
          name: 'programs',
          background: 'transparent',
        },
        {
          name: 'vacancies',
          background: 'transparent',
        },
        {
          name: 'teachers',
        },
        {
          name: 'students',
        },
        {
          name: 'infrastructure',
        },
        {
          name: 'sports',
        },
        {
          name: 'extracurriculars',
        },
        {
          name: 'languages',
        },
        {
          name: 'socials',
        },
        {
          name: 'audiovisual-material',
        },
        {
          name: 'testimonials',
        },
      ],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    setModal(modal) {
      this.currentModal = modal;
    },
  },
};
</script>
