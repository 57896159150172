<template>
  <div class="subsection-container">
    <!-- Todos, menos NH -->
    <div
      v-if="false"
      @click="goTo('notifications')"
      @keydown="goTo('notifications')"
    >
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="160"
        type="image"
      />
      <Subsection
        v-if="false"
        target="popup"
        bg-color="#F5F5F5"
        flex="column"
        clickable
        align="left"
      >
        <div class="widget-tool__messages">
          <div class="widget-tool__messages--title">
            {{ $t('dashboard.tools.messages') }}
          </div>
          <div class="widget-tool__messages--des color-admissions">
            {{ $t('dashboard.tools.messages-des') }}
          </div>
        </div>
        <img
          style="
          position: absolute;
          top: 15px;
          right: 15px;
          transform: translate(10%,0);
          width: 45%;
        "
          src="@/assets/dashboard/widgets/messages-widget.svg"
          alt="graph"
        />
      </Subsection>
    </div>
    <div
      v-if="false"
      @click="goTo('admissions')"
      @keydown="goTo('admissions')"
    >
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="160"
        type="image"
      />
      <Subsection
        v-if="loaded"
        target="popup"
        bg-color="#F5F5F5"
        flex="column"
        clickable
        align="left"
      >
        <div class="widget-tool color-admissions">
          {{ $t('dashboard.tools.admission') }}
        </div>
        <div class="widget-tool--des">
          {{ $t('dashboard.tools.admission-des') }}
        </div>
        <img
          style="
          position: absolute;
          bottom: 15px;
          transform: translate(10%,0);
          width: 75%;
        "
          src="@/assets/dashboard/widgets/admissions-widget.svg"
          alt="graph"
        />
      </Subsection>
    </div>
    <div v-if="false">
      <Subsection
        id="vacancies_by_stage_subsection"
        target="popup"
        bg-color="#F5F5F5"
        flex="column"
        align="center"
      >
        <LoaderCircular
          v-if="!widgetLoaded"
          :size="44"
          show
          style="position: absolute; left: calc(50% - 22px); top: calc(50% - 22px);"
        />
        <div v-if="!vacanciesWidgetUnavailable" style="height: 100%; width: 100%;">
          <!-- eslint-disable-next-line vuejs-accessibility/iframe-has-title -->
          <iframe
            id="vacancies_by_stage_graph"
            src=""
            style="width: 100%; height: 100%; transform: translateX(10%);"
            loading="lazy"
          />
        </div>
        <div v-else>
          <img
            style="position: absolute; top: 0px; right: 0px"
            :src="require('@/assets/dashboard/widgets/vacancies-stats.svg')"
            alt="tool"
          />
          <div :class="mobile ? 'tool-data top-tool loader-dashboard' : 'tool-data loader-dashboard'">
            <v-skeleton-loader
              v-if="!loaded"
              max-width="auto"
              max-height="220"
              type="button"
            />
            <div v-else>
              {{ vacancies }}
            </div>
          </div>
          <div class="tool-title">
            {{ $t('dashboard.tools.vacancies') }}
          </div>
        </div>
      </Subsection>
    </div>
    <div v-for="(tool, index) in tools" :key="index">
      <Subsection bg-color="#F5F5F5" flex="column" class="section-tool">
        <img
          style="position: absolute; top: 0px; right: 0px"
          :src="require('@/assets/dashboard/widgets/' + tool.icon)"
          alt="tool"
        />
        <div :class="mobile ? 'tool-data top-tool loader-dashboard' : 'tool-data loader-dashboard'">
          <v-skeleton-loader
            v-if="!loaded"
            max-width="auto"
            max-height="220"
            type="button"
          />
          <div v-else>
            {{ tool.data }}
          </div>
        </div>
        <div class="tool-title">
          {{ tool.title }}
        </div>
      </Subsection>
    </div>
  </div>
</template>

<script>
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';
import Subsection from './Subsection.vue';

export default {
  name: 'ToolsStats',
  components: { Subsection, LoaderCircular },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  metaInfo: {
    meta: [],
  },
  data() {
    return {
      favoritesSize: 0,
      views: 0,
      initialVacancies: 0,
      vacancies: 0,
      vacanciesWidgetUnavailable: false,
      widgetLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      campusDetail: 'roles/currentHeadMasterMainSchool',
      widgetsVacanciesByStageGraphURL: 'widgets/widgetsVacanciesByStageGraphURL',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tools() {
      // En NH se comenta igual vacantes
      const tools = [
        // {
        //   data: 1,
        //   title: 'Usuarios en tu barrio',
        //   icon: 'users-stats.svg',
        // },
        // {
        //   data: this.favoritesSize,
        //   title: 'Guardados en mi Lista',
        //   icon: 'favorites-stats.svg',
        // },
        // {
        //   data: this.views,
        //   title: this.$t('dashboard.tools.visit_profile'),
        //   icon: 'visits-stats.svg',
        // },
      ];
      return tools;
    },
  },
  mounted() {
    this.favoritesSize = this.favoriteSchoolsDetails.length;
    this.views = this.setViewsNum();
    this.initialVacancies = this.campusDetail?.vacancies;
    this.vacancies = this.initialVacancies.reduce((accumulator, object) => accumulator + object.regular_vacancies, 0);
    this.loadWidget();
  },
  methods: {
    ...mapActions({
      getWidgetsVacanciesByStageGraph: 'widgets/getWidgetsVacanciesByStageGraph',
    }),
    async loadWidget() {
      this.widgetLoaded = false;
      const sectionBounding = document.getElementById('vacancies_by_stage_subsection').getBoundingClientRect();
      const params = {
        campusCode: this.campusDetail.campus_code,
        size: Math.min(sectionBounding.height, sectionBounding.width) - 40,
        title: this.$t('dashboard.tools.vacanciesTitle'),
      };
      await this.getWidgetsVacanciesByStageGraph(params);
      if (this.widgetsVacanciesByStageGraphURL) {
        const graph = document.getElementById('vacancies_by_stage_graph');
        graph.src = await this.widgetsVacanciesByStageGraphURL;
        this.widgetLoaded = true;
      } else {
        this.widgetLoaded = true;
        this.widgetDefaul();
      }
    },
    widgetDefaul() {
      this.vacanciesWidgetUnavailable = true;
    },
    goTo(page) {
      this.$router.push(page);
      if (page === 'admissions') {
        trackMixPanel('click_dashboard_module_admissions');
      }
      if (page === 'notifications') {
        trackMixPanel('click_dashboard_messages_screen');
      }
    },
    setViewsNum() {
      const listOfEvents = this.campusDetail?.num_general_views ?? [];
      const totalViews = listOfEvents?.agrupado?.filter(({ event }) => event === 'click_campus_card' || event === 'open_digital_profile_from_url')
        ?.reduce((acc, { total }) => acc + total, 0) ?? 0;

      return totalViews;
    },
  },
};
</script>
