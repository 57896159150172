<template>
  <section
    class="clickable"
    @click="externalGoTo()"
    @keydown="externalGoTo()"
  >
    <ImageAtom
      :src="require(`@/assets/dashboard/widgets/${tenant}-white-label.svg`)"
      :alt="'white-label'"
      height="100%"
      width="100%"
      object-fit="contain"
    />
    <div class="text-left">
      <TextAtom
        class="mt-3 mb-1"
        :value="`dashboard.digital_profile.white-label.${tenant}-title`"
        :color="'primary-dark'"
        font="poppins"
        weight="500"
        size="title"
      />
      <TextAtom
        v-if="false"
        :value="`dashboard.digital_profile.white-label.${tenant}-subtitle`"
        :color="'primary-dark'"
        font="poppins"
        weight="400"
        size="normal"
      />
    </div>
    <div class="white-label--col">
      <div
        v-for="(data, index) in $t(`dashboard.digital_profile.white-label.${tenant}-info`)"
        :key="index"
        class="white-label--each"
      >
        <h3 class="white-label--each__one">
          {{ data.title }}
        </h3>
        <p class="white-label--each__two">
          {{ data.description }}
        </p>
        <p class="white-label--each__three">
          {{ data.number }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'WhiteLabel',
  components: {
    ImageAtom,
    TextAtom,
  },
  data() {
    return {
      tenant: TENANT_CONFIGURATION.ABBREVIATION,
    };
  },
  methods: {
    externalGoTo() {
      const page = this.$t(`dashboard.digital_profile.white-label.${this.tenant}-external-link`);
      if (this.tenant === 'NH' || this.tenant === 'CL' || this.tenant === 'PAL') {
        window.open(page, '_blank').focus();
      }
    },
  },
};
</script>
