<template>
  <section>
    <Location
      v-if="locationStep === 1"
      is-in-map
      :in-simulation="inSimulation"
      :in-welcome="inWelcome"
      :pre-created="preCreated"
      @backStep="back ? $emit('backStep') : $emit('closeModal')"
      @setCommune="setCommune"
      @setUserLocation="setUserLocation"
      @skip="$emit('skip'); $router.push('/map')"
    />
    <div v-if="!getLocationLoader && inWelcome" class="welcome-explorer__register--loader">
      <ModalContent
        :content-sections="['loader']"
        :loader-size="80"
        :loader-width="8"
        :instruction-text="'user_register.register_location.give_permission'"
      />
    </div>
    <div
      v-if="locationStep === 2 && getLocationLoader"
      style="height: 100%"
      class="d-flex flex-column justify-space-between"
      :class="inWelcome ? 'w-full h-full' : ''"
    >
      <div>
        <Actions
          v-if="!inSimulation && !inWelcome"
          style="height: 12px; margin-bottom: 12px"
          @back="locationStep = 1"
        />
        <ModalHeader
          v-if="inWelcome"
          :content-sections="['actions']"
          :actions-tag="getActionsTags()"
          white
          class="welcome-explorer__register--back"
          @back="backStep()"
        />
        <RegisterLogo
          v-if="!inSimulation && !inWelcome"
          inline
          small
        />
        <SmallTitle
          v-if="!inSimulation && !inWelcome"
          style="margin-top: 0px !important"
          center-left
          small-top-margin
          :text="'user_register.register_location.title'"
        />
        <TitleInstructions v-if="!inWelcome" :text="'shared.location.title'" style="margin-bottom: 12px" />
        <SearchLocation
          is-in-map
          :height="inWelcome ? '0px' : '428px'"
          :height-mobile="inWelcome ? '0px' : '24rem'"
          :in-welcome="inWelcome"
          :commune="selectedCommune"
          :user-location="userLocation"
          :research-locale-key-root="researchLocaleKeyRoot"
          :is-draggable="isDraggable"
          @setUserLocation="setUserLocation"
        />
      </div>
    </div>
    <div
      v-if="locationStep === 2"
      class="d-flex justify-end fill-width"
      :style="!inWelcome ? 'padding: 20px 0 0 0;' : ''"
    >
      <MainButton
        v-if="preCreated && !researchLocaleKeyRoot.includes('research-cl')"
        medium
        :text="inSimulation
          ? 'user_register.brother_in_campus.cancel' : 'start_explorer.new_flow.change_location'"
        :style="`margin-right: ${inSimulation ? '16px' : '0'}` "
        with-border
        :class="mobile ? 'welcome-explorer__continue--mobile-skip' : 'welcome-explorer__change-location pr-5'"
        @continue="$emit('changeLocation')"
      />
      <MainButton
        v-if="(getLocationLoader && inWelcome) || !inWelcome"
        is-active
        :small="!inWelcome"
        :medium="inWelcome"
        :text="inSimulation
          ? 'user_register.brother_in_campus.cancel' : 'start_explorer.new_flow.confirm_location'"
        :light="inSimulation || inWelcome"
        :style="`margin-right: ${inSimulation ? '16px' : '0'}` "
        :class="mobile && inWelcome ? 'welcome-explorer__continue--mobile-location'
          : inWelcome ? 'welcome-explorer__continue' : ''"
        @continue="inSimulation ? backStep() : setLocation()"
      />
      <MainButton
        v-if="inSimulation"
        is-active
        small
        :text="'user_register.continue'"
        style="width: max-content"
        @continue="setLocation()"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MainButton from '@/components/atoms/buttons/MainButton.vue';
import Actions from '@/components/atoms/headers/Actions.vue';
import RegisterLogo from '@/components/atoms/logos/RegisterLogo.vue';
import TitleInstructions from '@/components/atoms/subtitles/TitleInstructions.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import Location from '@/components/organisms/register/Location.vue';
import SearchLocation from '@/components/shared/SearchLocation.vue';
import trackMixPanel from '@/utils/mixpanel';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

export default {
  name: 'LocationFlow',
  components: {
    SearchLocation,
    SmallTitle,
    TitleInstructions,
    MainButton,
    RegisterLogo,
    Location,
    Actions,
    ModalContent,
    ModalHeader,
  },
  props: {
    selectStep: {
      type: Number,
      default: null,
    },
    inSimulation: {
      type: Boolean,
      default: false,
    },
    inDashboard: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
    inWelcome: {
      type: Boolean,
      default: false,
    },
    preCreated: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: true,
    },
    sendLocationGuest: {
      type: Boolean,
      default: false,
    },
    hasInitialLocation: {
      type: Boolean,
      default: false,
    },
    researchLocaleKeyRoot: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      locationStep: null,
      userLocation: null,
      selectedCommune: null,
      selectedLocation: null,
      finalLat: null,
      finalLng: null,
      locationT: '',
      initialLat: null,
      initialLng: null,
      getLocationLoader: true,
    };
  },
  computed: {
    ...mapGetters({
      municipalities: 'options/municipalities',
      isGuestClickFirstTime: 'authentication/isGuestClickFirstTime',
      isGuest: 'authentication/isGuest',
      lgUuid: 'authentication/legalGuardianUUID',
      currentStudent: 'authentication/currentStudent',
      locationRegister: 'userRegister/location',
      locationType: 'userRegister/locationType',
      place: 'userRegister/place',
      initialCoordinates: 'userRegister/initialCoordinates',
      pinMoved: 'userRegister/pinMoved',
      sessionUUID: 'authentication/sessionUUID',
      homeLocation: 'authentication/homeLocation',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  watch: {
    selectStep: {
      handler(newVal) {
        this.locationStep = newVal;
      },
    },
  },

  mounted() {
    this.locationStep = this.selectStep;
    const value = {
      formatted_address: null,
      geocodification_type: null,
      lat: null,
      lng: null,
      source: null,
      upper_location: {},
      street_name: null,
      street_number: null,
    };
    const coordinates = {
      lat: null,
      lng: null,
    };
    const place = {
      display: null,
      display1: null,
      display2: null,
      code_national_id: null,
      lat: null,
      lng: null,
      p_location_id: null,
      searchTerm: null,
    };
    this.changeLatLong({ lat: coordinates.lat, lng: coordinates.lng });
    this.setPinMoved({ pinMoved: false });
    this.setInitialCoordinates({ coordinates });
    if (!this.inWelcome) {
      this.changePlace({ place });
      this.setFinalLocationAddress({ address: value });
      this.changeLocationFormattedAddress({ locationAddress: null });
    }
    this.changeLocationSource({ locationSource: '' });
    this.setLocationAddress({ searchTerm: null });
    this.changeGeoType({ geoType: '' });
  },
  methods: {
    ...mapActions({
      setIsGuestClickFirstTime: 'authentication/setIsGuestClickFirstTime',
      setFastRegisterLocation: 'userRegister/setFastRegisterLocation',
      setGuestLocation: 'authentication/setGuestLocation',
      setStudentLocation: 'newStudentRegister/setStudentLocation',
      sendGeocodingHistory: 'geoTools/sendGeocodingHistory',
      setFinalLocationAddress: 'userRegister/setFinalLocationAddress',
      changeLocationFormattedAddress: 'userRegister/changeLocationFormattedAddress',
      changeLocationSource: 'userRegister/changeLocationSource',
      setLocationAddress: 'userRegister/setLocationAddress',
      changeGeoType: 'userRegister/changeGeoType',
      setPinMoved: 'userRegister/setPinMoved',
      changeLatLong: 'userRegister/changeLatLong',
      changePlace: 'userRegister/changePlace',
      setInitialCoordinates: 'userRegister/setInitialCoordinates',
      setGuestSimulationData: 'simulation/setGuestSimulationData',
      setGuestData: 'authentication/setGuestData',
      navigateTo: 'explorer/navigateTo',
      setRegisterAddressGuest: 'userRegister/setRegisterAddressGuest',
    }),
    backStep() {
      if (this.locationStep !== 1 && !this.preCreated) {
        this.locationStep -= 1;
        return this.locationStep;
      }
      return this.$emit('backStep');
    },
    setCommune(commune) {
      const communeDetails = this.municipalities.find(
        (detailedCommune) => detailedCommune.name === commune,
      );
      this.selectedLocation = {
        coordinates: {
          lat: communeDetails.default_coordinate.lat,
          lng: communeDetails.default_coordinate.lon,
        },
        municipalityName: commune,
        addressDetails: commune,
      };
      this.userLocation = null;
      this.selectedCommune = commune;
      this.locationStep = 2;
    },
    setUserLocation(location) {
      this.selectedCommune = null;
      this.userLocation = location;
      this.locationStep = 2;
      this.$emit('locationSelected', location.coordinates);
    },
    geocodingHistory() {
      if (this.locationType === 1) {
        this.locationT = 'use_my_location';
      } else if (this.locationType === 2) {
        this.locationT = 'write_address';
      } else if (this.locationType === 3) {
        this.locationT = 'locate_in_map';
      }
      this.initialLat = this.initialCoordinates.lat;
      this.initialLng = this.initialCoordinates.lng;

      const geoInfo = {
        user: this.lgUuid || null,
        geotools_code_national: this.locationRegister?.code_national,
        final_lat: this.finalLat,
        final_lng: this.finalLng,
        used_text_first: this.locationType === 2,
        method: this.locationT,
        geotools_lat: this.initialLat,
        geotools_lng: this.initialLng,
        geotools_geocodification_type: this.locationRegister?.final_address?.geocodification_type !== '' ? this.locationRegister?.final_address?.geocodification_type : null,
        geotools_address: this.locationRegister?.final_address?.formatted_address !== '' ? this.locationRegister?.final_address?.formatted_address : '',
        user_text_input: this.locationRegister?.address_searched ?? null,
      };
      this.sendGeocodingHistory({ geoInfo });
    },
    setLocation() {
      let location = {};
      let addressDetails;
      if (this.isGuest) {
        if (!this.isGuestClickFirstTime.location) {
          this.setIsGuestClickFirstTime({ property: 'location' });
        }
        if (!this.userLocation && !this.inWelcome) {
          this.setGuestLocation({ location: this.selectedLocation });
        } else if (this.userLocation?.addressDetails) {
          this.setGuestLocation({ location: this.userLocation });
        } else {
          addressDetails = this.locationRegister?.final_address?.formatted_address !== null
            ? this.locationRegister?.final_address?.formatted_address
            : '';
          if (!this.pinMoved && this.locationRegister?.final_address?.formatted_address === null) {
            this.finalLat = this.place.lat;
            this.finalLng = this.place.lng;
          } else if (this.locationRegister?.final_address?.lat !== '') {
            this.finalLat = this.locationRegister?.final_address.lat;
            this.finalLng = this.locationRegister?.final_address.lng;
          } else {
            this.finalLat = this.initialCoordinates.lat;
            this.finalLng = this.initialCoordinates.lng;
          }
          location = {
            addressDetails,
            coordinates: {
              lat: this.finalLat,
              lng: this.finalLng,
            },
            municipalityName: this.place.display1,
          };
          if (this.$route.name === 'Simulate') {
            this.setGuestSimulationData({ key: 'city', value: this.place.display1 });
            this.setGuestSimulationData({ key: 'lat', value: this.finalLat });
            this.setGuestSimulationData({ key: 'long', value: this.finalLng });
          } else {
            this.setGuestLocation({ location });
          }
          this.$emit('locationSelected', location.coordinates);
          if (this.sendLocationGuest) {
            const payloadRegisterAddressGuest = {
              uuid: null,
              address_details:
                this.locationRegister?.final_address?.formatted_address || this.locationRegister?.address_searched || '',
              address_number: this.locationRegister?.final_address?.street_number !== null
                ? this.locationRegister?.final_address?.street_number
                : null,
              address_street: this.locationRegister?.final_address?.street_name !== null
                ? this.locationRegister?.final_address?.street_name : null,
              address_lat: this.finalLat,
              address_lon: this.finalLng,
              user: this.sessionUUID || null,
              main_address: true,
              correct_location: null,
              location_id: this.place.p_location_id,
            };
            this.setRegisterAddressGuest({ location: payloadRegisterAddressGuest });
          }
          if (this.inWelcome) {
            this.setGuestData({
              location,
              grades: TENANT_CONFIGURATION.DEFAULTS.GRADES,
            }).then(() => {
              this.navigateTo({ lat: location.coordinates.lat, lng: location.coordinates.lng }).then(
                () => {
                  this.$emit('nextStep');
                },
              );
            });
          }
          trackMixPanel('click_UsuariosGuest_4_Continuar');
          this.setGuestLocation({ location });
          this.geocodingHistory();
        }
      } else {
        if (!this.pinMoved && this.locationRegister?.final_address?.formatted_address === null && !this.inWelcome) {
          this.finalLat = this.place.lat;
          this.finalLng = this.place.lng;
        } else if (this.locationRegister?.final_address?.lat) {
          this.finalLat = this.locationRegister?.final_address.lat;
          this.finalLng = this.locationRegister?.final_address.lng;
        } else {
          this.finalLat = this.initialCoordinates.lat;
          this.finalLng = this.initialCoordinates.lng;
        }
        if (this.locationRegister?.final_address?.formatted_address !== null) {
          addressDetails = this.locationRegister.final_address.formatted_address;
        } else if (this.locationRegister?.address_searched) {
          addressDetails = this.locationRegister.address_searched;
        } else {
          addressDetails = '';
        }
        location = {
          uuid: null,
          address_details: addressDetails,
          address_name: null,
          address_number: this.locationRegister?.final_address?.street_number !== null
            ? this.locationRegister?.final_address?.street_number
            : null,
          address_street: this.locationRegister?.final_address?.street_name !== null
            ? this.locationRegister?.final_address?.street_name : null,
          address_lat: this.finalLat,
          address_lon: this.finalLng,
          location: this.place.display1,
          location_id: this.place.p_location_id,
        };
        this.$emit('locationSelected', { lat: this.finalLat, lng: this.finalLng });
        if (this.preCreated && this.hasInitialLocation) {
          location.correct_location = !!(
            this.finalLat === this.homeLocation.lat && this.finalLng === this.homeLocation.lng
          );
          if (!location.correct_location) {
            trackMixPanel('click_UsuariosPreCreados_4_ChangeLocation');
          }
        } else {
          location.correct_location = null;
        }
        if (this.inDashboard || Object.keys(this.currentStudent).length === 0) {
          location.uuid = this.lgUuid;
          this.setFastRegisterLocation({ location });
        } else {
          location.uuid = this.currentStudent.uuid;
          this.setStudentLocation({ location, updateStudent: true });
        }
        this.$emit('locationSelected', { lat: this.finalLat, lng: this.finalLng });
        this.geocodingHistory();
      }
      this.$emit('closeModal');
      this.locationStep = null;
      return this.locationStep;
    },
    getActionsTags() {
      return 'click_reg_detailed-register-choose-location_back-button';
    },
  },
};
</script>
