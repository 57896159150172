<template>
  <section
    class="d-flex flex-row h-100 w-100 justify-center align-center"
    :class="{ 'cursor-pointer': hasFeedback?.status }"
    @click="openFeedback()"
    @keydown="openFeedback()"
  >
    <img
      :src="require(`@/assets/dashboard/widgets/${getWidgetFeedback}`)"
      alt="widget feedback"
      width="120"
    />
    <div class="w-full d-flex flex-column pl-2">
      <TextAtom
        :value="getTitleWidgetFeedback"
        tag="h1"
        font="poppins"
        color="primary-dark"
        size="normal"
        weight="500"
        class="text-left"
      />
      <TextAtom
        :value="$t('dashboard.digital_profile.feedback.description')"
        tag="span"
        font="inter"
        color="neutral-700"
        size="small"
        weight="400"
        class="text-left"
      />
    </div>
  </section>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FeedbackWidget',
  components: {
    TextAtom,
  },
  computed: {
    ...mapGetters({
      hasFeedback: 'feedback/hasFeedbackIntervention',
      feedback: 'feedback/feedback',
    }),
    getWidgetFeedback() {
      const nameWidget = 'feedback-{locale}-widget.svg';
      return nameWidget.replace('{locale}', this.$i18n.locale);
    },
    getTitleWidgetFeedback() {
      return this.hasFeedback?.status
        ? this.$t('dashboard.digital_profile.feedback.title-available')
        : this.$t('dashboard.digital_profile.feedback.title-not-available');
    },
  },
  mounted() {
    this.getHasFeedbackIntervention();
  },
  methods: {
    ...mapActions({
      getHasFeedbackIntervention: 'feedback/getHasFeedbackIntervention',
      setShowFeedback: 'feedback/setShowFeedback',
    }),
    openFeedback() {
      return this.hasFeedback?.status ? this.setShowFeedback(true) : null;
    },
  },
};
</script>
