<template>
  <div>
    <div class="pt-5 upcoming-events">
      <div>
        <div class="flex">
          <div class="current-date">
            <p class="current-date--name">
              {{ dayName }}
            </p>
            <p class="current-date--number">
              {{ day }}
            </p>
          </div>
          <div class="closest-event">
            <p class="closest-event--name">
              {{ closestEvent.name }}
            </p>
            <!-- <p class="closest-event--deadline">
                {{ $t('dashboard.calendar.events-below') }}
              </p> -->
            <div />
          </div>
        </div>
        <div class="upcoming-events--container">
          <div class="upcoming-events--list">
            <div
              v-for="(event, index) in $t(`dashboard.calendar.${tenant}-dates`)"
              :key="index"
              class="flex upcoming-events--item"
            >
              <div class="upcoming-events--item-dot" />
              <div class="upcoming-events--border flex">
                <div>
                  <p class="upcoming-events--item-date">
                    {{ event.dateText }}
                  </p>
                  <p class="upcoming-events--item-name">
                    {{ event.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TENANT_CONFIGURATION } from '@/constants/tenant';

export default {
  name: 'UpcomingEvents',
  data() {
    return {
      day: false,
      dayName: '',
      closestEvent: {},
      tenant: TENANT_CONFIGURATION.ABBREVIATION,
    };
  },
  mounted() {
    const dayNumber = new Date().getDay();
    const arrayDay = [...this.$t('dashboard.calendar.day-of-week')];
    this.dayName = arrayDay[dayNumber];
    this.day = new Date().getDate();
    const today = new Date();
    const datesArray = [...this.$t(`dashboard.calendar.${this.tenant}-dates`)];
    this.closestEvent = this.findClosestEvent(today, datesArray);
  },
  methods: {
    findClosestEvent(today, datesArray) {
      let closestIndex = 0;
      let closestDiff = Math.abs(today - new Date(datesArray[0].date));
      const filteredDates = datesArray.filter((date) => new Date(date.date) > today);
      for (let i = 1; i < filteredDates.length; i += 1) {
        const dateDiff = Math.abs(today - new Date(filteredDates[i]));
        if (dateDiff < closestDiff) {
          closestIndex = i;
          closestDiff = dateDiff;
        }
      }
      return filteredDates[closestIndex];
    },
  },
};
</script>
