var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection-container",style:({ '--n-rows': 2 })},[_c('Subsection',{style:({
      'background-image':
        'url('
        + require('@/assets/dashboard/widgets/explorer-widget.svg')
        + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'border-radius': '5px',
      'background-position': 'center',
    }),attrs:{"bg-color":"transparent","flex":"column","clickable":""},on:{"onClick":function($event){return _vm.goTo('map')}}},[_c('div',{directives:[{name:"t",rawName:"v-t",value:('dashboard.tools.explorer'),expression:"'dashboard.tools.explorer'"}],staticClass:"widget-tool"})]),(false)?_c('Subsection',{attrs:{"bg-color":"#F5F5F5","flex":"column","clickable":"","align":"right"},on:{"onClick":function($event){return _vm.toggleModal()}}},[_c('div',{staticClass:"widget-tool"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.tools.resume-postulacion"))+" ")]),_c('div',{staticClass:"widget-tool--des"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.tools.resume-postulacion-des"))+" ")]),_c('img',{staticStyle:{"position":"absolute","bottom":"8px","margin-left":"4px","margin-right":"auto","width":"85%","height":"50%"},attrs:{"src":require("@/assets/dashboard/widgets/graph-app-widget.svg"),"alt":"graph"}})]):_vm._e(),_c('Location')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }