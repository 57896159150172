<template>
  <div class="dashboard-section-content position-relative">
    <DFMModal :current-modal="currentModal" @setModal="setModal" />
    <div style="position-relative h-100">
      <img
        src="@/assets/dashboard/widgets/DFM-widget.svg"
        class="dfm-title"
        alt="DFM-Logo"
      />
      <div class="text-dfm">
        {{ $t("dashboard.dfm.des") }}
      </div>
    </div>
    <div
      class="dashboard-button dashboard-dfm-button-sumate"
      @click="
        externalGoTo('https://colombia.decidiendofuturomejor.com/postular')
      "
      @keydown="
        externalGoTo('https://colombia.decidiendofuturomejor.com/postular')
      "
    >
      {{ $t("dashboard.dfm.invitation") }}
    </div>
    <div
      class="dashboard-button dashboard-dfm-button"
      @click="setModal('information')"
      @keydown="setModal('information')"
    >
      {{ $t("dashboard.dfm.sumate") }}
    </div>
  </div>
</template>
<script>
import DFMModal from '@/components/dashboard/modals/DFMModal.vue';
import trackMixPanel from '@/utils/mixpanel';
import { mapGetters } from 'vuex';

export default {
  name: 'DFMSection',
  components: {
    DFMModal,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    meta: [],
  },
  data() {
    return {
      currentModal: null,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  mounted() {
    const isWidgetDfm = this.$route.query?.widget === 'dfm';

    if (isWidgetDfm) {
      this.setModal('information');
    }
  },
  methods: {
    setModal(modal) {
      this.currentModal = modal;
      if (modal === 'information') {
        trackMixPanel('click_dashboard_module_dfm', {
          campus_code: this.campusDetail.campus_code,
          institution_code: this.campusDetail.institution_code,
        });
      }
    },
    externalGoTo(page) {
      window.open(page, '_blank').focus();
    },
  },
};
</script>
